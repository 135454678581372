<template>
  <div
    class="main"
    style="display:flex;flex-direction: column"
  >
    <div style="display: flex; gap: 10px; justify-content: center;">
      <b-form-group
        :label="$t('From:')"
      >
        <date-picker
          v-model="fromDate"
          value-type="format"
          class="form-control"
          format="YYYY-MM-DD"
          type="date"
          :placeholder="$t('SelectD')"
          style="width: 100%;"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group :label="$t('To:')">
        <date-picker
          v-model="toDate"
          value-type="format"
          class="form-control"
          format="YYYY-MM-DD"
          type="date"
          :placeholder="$t('SelectD')"
          style="width: 100%;"
          :clearable="false"
        />
      </b-form-group>
    </div>
    <div
      style="margin: 3% 0%;"
    >
      <Bar
        :chart-options="barChartOptions"
        :chart-data="barChartData"
        :chart-id="barChartId"
        :dataset-id-key="barDatasetIdKey"
        :css-classes="barCssClasses"
        :styles="barStyles"
        :width="650"
        :height="barHeight"
        style="padding: 70px"
      />
    </div>
    <div class="monthly">

      <div
        id="chart"
        style="
          width: 740px;
          border: 1px solid #DDE0E5;
          padding: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;

        "
      >
        <p>{{ $t('Expenses') }}</p>
        <Pie
          v-b-tooltip.hover
          :title="$t('HoverOnData')"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="pieChartId"
          :dataset-id-key="pieDatasetIdKey"
          :width="650"
          :height="pieHeight"
        />
      </div>
      <div>
        <div
          id="chart2"
          style="
          width: 740px;
          border: 1px solid #DDE0E5;
          padding: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
        >
          <p>{{ $t('ExpensesByPercentage') }}: {{ valueExpenses }}</p>
          <Pie
            :chart-options="chartOptions1"
            :chart-data="chartData1"
            :chart-id="pieChartId1"
            :dataset-id-key="pieDatasetIdKey"
            :width="650"
            :height="pieHeight"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Bar, Pie } from 'vue-chartjs/legacy';
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';



import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  RadialLinearScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
  ArcElement,
);

export default {
  name: 'CombinedCharts',
  components: {
    Bar,
    Pie,
    DatePicker,
  },
  data() {
    return {
      toDate: moment().format('YYYY-MM-DD'),
      fromDate: moment().format('YYYY-MM-DD'),
      // first pie chart
      pieChartId: 'pie',
      pieDatasetIdKey: 'label',
      pieWidth: 400,
      pieHeight: 400,
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            expenseMainTypeId: [],
            label: [],
          },
        ],
      },
      hoverTimeout: null,
      previousValue: null,
      valueExpenses: null,
      chartOptions: {
        plugins: {
          datalabels: {
            align: 'start',
            anchor: 'end',
          },
        },
        // Other chart options here
        onHover: this.onChartHover,
        hasHovered: false,
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
      },
      chartData1: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      chartOptions1: {
        plugins: {
          datalabels: {
            align: 'start',
            anchor: 'end',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
      },
      // Bar Chart data and options
      barChartId: 'bar-chart',
      barDatasetIdKey: 'label',
      barWidth: 400,
      barHeight: 400,
      barCssClasses: '',
      barStyles: {},
      barChartData: {
        labels: [],
        datasets: [],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getCostRevenue', 'getExpenses', 'getExpensesMain']),
    returnMonth() {
      // Clone the array to avoid mutating the original array
      const sortedArray = [...this.getCostRevenue];

      // Sort the array based on the 'month' property
      sortedArray.sort((a, b) => { return a.week - b.week });

      // Return the sorted array
      return sortedArray;
    },
    returnExpenses() {
      return this.getExpenses;
    },
    returnExpensesMain() {
      return this.getExpensesMain
    },
  },
  watch: {
    fromDate(value) {
      this.showCostRevenue({ from: value, to: this.toDate })
      this.percentageExpenses({ from: value, to: this.toDate })
      if (this.previousValue) {
        this.showExpenesMain({
          from: value,
          to: this.toDate,
          expenseMainTypeID: this.previousValue,
        });
      }
      if (value > this.toDate) {
        this.toDate = value
      }
    },
    toDate(value) {
      this.showCostRevenue({ from: this.fromDate, to: value })
      this.percentageExpenses({ from: this.fromDate, to: value })
      if (this.previousValue) {
        this.showExpenesMain({
          from: this.fromDate,
          to: value,
          expenseMainTypeID: this.previousValue,
        });
      }
      if (value < this.fromDate) {
        this.fromDate = value
      }
    },
    getCostRevenue: {
      immediate: true,
      handler() {
        if (this.getCostRevenue) {
          this.updateChartData();
        }
      },
    },
    getExpenses: {
      immediate: true,
      handler() {
        if (this.getExpenses) {
          this.updateChartData2();
        }
      },
    },
    getExpensesMain: {
      immediate: true,
      handler() {
        if (this.getExpensesMain) {
          this.updateChartData3();
        }
      },
    },
  },
  mounted() {
    this.showCostRevenue({ from: this.fromDate, to: this.toDate })
    this.percentageExpenses({ from: this.fromDate, to: this.toDate })
  },
  methods: {
    ...mapActions(['showCostRevenue', 'percentageExpenses', 'showExpenesMain']),
    updateChartData() {
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];

      this.barChartData.labels = this.returnMonth.map((value) => { return monthNames[value.month - 1] });
      this.barChartData.datasets = [
        {
          label: `${this.$t('Income')}`,
          backgroundColor: ['#55B94A'],
          data: this.returnMonth.map((value) => { return value.incomeAmount }),
        },
        {
          label: `${this.$t('Outcome')}`,
          backgroundColor: ['#1970AB'],
          data: this.returnMonth.map((value) => { return value.outcomeAmount }),
        },
      ];
    },
    updateChartData2() {
      this.chartData.labels = this.returnExpenses.map((value) => { return value.name })
      this.chartData.datasets = [
        {
          backgroundColor: this.returnExpenses.map((value) => { return value.hashColor }),
          data: this.returnExpenses.map((value) => { return value.percentage }),
          expenseMainTypeId: this.returnExpenses.map((value) => { return value.expenseMainTypeId }),
          label: this.returnExpenses.map((value) => { return value.name }),
        },
      ]
    },
    updateChartData3() {
      this.chartData1.labels = this.returnExpensesMain.map((value) => { return value.name })
      this.chartData1.datasets = [
        {
          backgroundColor: this.returnExpensesMain.map((value) => { return value.hashColor }),
          data: this.returnExpensesMain.map((value) => { return value.amount }),
        },
      ]
    },
    onChartHover(event, chartElement) {
      if (chartElement.length) {
        const index = chartElement[0].index;
        const datasetIndex = chartElement[0].datasetIndex;
        const value = this.chartData.datasets[datasetIndex].expenseMainTypeId[index];
        const valueOfExpenses = this.chartData.datasets[datasetIndex].label[index]
        if (value !== this.previousValue) {
          clearTimeout(this.hoverTimeout);
          this.hoverTimeout = setTimeout(() => {
            console.log(`Hovered value: ${value}`);
            this.showExpenesMain({
              from: this.fromDate,
              to: this.toDate,
              expenseMainTypeID: value,
            });
            this.previousValue = value;
            this.valueExpenses = valueOfExpenses
          }, 500);
        }
      } else {
        clearTimeout(this.hoverTimeout);
      }
    },

  },
};
</script>

<style scoped>
.monthly {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

@media screen and (max-width: 1200px) {
  .monthly {
    display: flex;
    flex-direction: column;
  }
}
</style>
