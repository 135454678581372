<template>
  <div>
    <MonthlyIncome />
  </div>
</template>

<script>
import MonthlyIncome from '../components/employeePayments/MonthlyIncome.vue'

export default {
  components: {
    MonthlyIncome,
  },
}
</script>

<style>

</style>
