import { render, staticRenderFns } from "./MonthlyIncome.vue?vue&type=template&id=52888382&scoped=true"
import script from "./MonthlyIncome.vue?vue&type=script&lang=js"
export * from "./MonthlyIncome.vue?vue&type=script&lang=js"
import style0 from "./MonthlyIncome.vue?vue&type=style&index=0&id=52888382&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52888382",
  null
  
)

export default component.exports